import { ajaxHandler, AjaxHandlerResponse, AjaxHandlerResponseError } from '~components/AjaxHandler';
import { validator } from '~components/Validator';
import { serialize } from 'dom-form-serializer';
import { BasicObject } from '~components/ObjectHelper';
import modal from '~components/Modal';

const AjaxForm = (): { init: () => void } => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getValueByKeyChain = (object: BasicObject, keychain: Array<string>): any => {
        const key = keychain.shift();
        if (typeof key !== 'string') {
            return null;
        }
        // if it's the last key in the chain, assign the value directly
        if (keychain.length === 0) {
            return object[key];
        }
        return getValueByKeyChain(object[key], keychain);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setValueByKeyChain = (object: BasicObject, keychain: Array<string>, value: any): void => {
        const key = keychain.shift();
        if (typeof key === 'undefined') {
            return;
        }
        if (keychain.length === 0) {
            object[key] = value;
            return;
        }
        setValueByKeyChain(object[key], keychain, value);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deleteDataByKeyChain = (object: BasicObject, keychain: Array<string>): void => {
        const key = keychain.shift();
        if (typeof key === 'undefined') {
            return;
        }
        if (keychain.length === 0) {
            delete object[key];
            return;
        }
        deleteDataByKeyChain(object[key], keychain);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const convertDataType = (value: any, type: string): any => {
        switch (type) {
            case '?string':
                return '' === value ? null : value.toString();
            case 'int':
                return '' === value ? 0 : parseInt(value);
            case '?int':
                return '' === value ? null : parseInt(value);
            case 'float':
                return '' === value ? 0 : parseFloat(value);
            case '?float':
                return '' === value ? null : parseFloat(value);
            case 'int[]':
                return Array.isArray(value) ? value.map(val => parseInt(val)) : [];
            case 'bool':
                return value === true || value === 1 || value === '1' || value === 'true' || value === 'yes';
        }
        return value;
    };

    const convertDataTypes = (formElement: HTMLFormElement, data: BasicObject): void => {
        const inputs = formElement.querySelectorAll('*[data-value-type]');
        inputs.forEach((element: Element): void => {
            const name = element.getAttribute('name');
            const type = element.getAttribute('data-value-type');
            if (null === name || null === type) {
                return;
            }
            const keychain = name.split('.');
            const value = getValueByKeyChain(data, [...keychain]);
            const convertedValue = convertDataType(value, type);
            setValueByKeyChain(data, [...keychain], convertedValue);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deleteEmptyObjects = (object: BasicObject, keychain: Array<string>): void => {
        const key = keychain.shift();
        if (typeof key === 'undefined') {
            return;
        }

        if (JSON.stringify(object[key]) === JSON.stringify({})) {
            delete object[key];
            return;
        }

        if (keychain.length === 0) {
            return;
        }

        deleteEmptyObjects(object[key], keychain);
    };

    const removeOnNull = (formElement: HTMLFormElement, data: BasicObject): void => {
        const inputs = formElement.querySelectorAll('*[data-delete-on-null]');
        inputs.forEach((element: Element): void => {
            const name = element.getAttribute('name');
            if (null === name) {
                return;
            }
            const keychain = name.split('.');
            const value = getValueByKeyChain(data, [...keychain]);
            if (null === value) {
                deleteDataByKeyChain(data, [...keychain]);
                deleteEmptyObjects(data, [...keychain]);
            }
        });
    };

    const simpleFormDataCollect = (formElement?: HTMLFormElement): string => {
        if (!formElement) {
            return '{}';
        }
        const data = serialize(formElement, {
            keySplitter: (key: string): Array<string | string[]> => {
                const path: Array<string | string[]> = key.split('.');
                let last: string | string[] | undefined = path.pop();
                if (last === undefined) {
                    return [];
                }
                if (!Array.isArray(last) && last.endsWith('[]')) {
                    last = [last.slice(0, -2)];
                }
                path.push(last);
                return path;
            },
        });
        delete data['']; // when input has no name attribute
        convertDataTypes(formElement, data);
        removeOnNull(formElement, data);

        return JSON.stringify(data);
    };

    const addFormMessage = (
        response: AjaxHandlerResponseError | AjaxHandlerResponse,
        type: 'success' | 'error',
        formId: string | null,
    ): void => {
        if (response.type !== 'message' && response.type !== 'error') {
            return;
        }

        const alert = `<div class="alert ${type}">${response.message}</div>`;
        let messageElementSelector = '[data-ajax-form-message]';

        if (null !== formId) {
            messageElementSelector = '[data-ajax-form-message="' + formId + '"]';
        }

        const messageElement: null | Element = document.querySelector(messageElementSelector);

        if (null === messageElement) {
            return;
        }

        messageElement.innerHTML = alert;

        if ('success' === type) {
            messageElement.scrollIntoView();
        }
    };

    const hideFormPart = (formId: string | null): void => {
        let hideElementSelector = '[data-ajax-success-hide]';

        if (null !== formId) {
            hideElementSelector = '[data-ajax-success-hide="' + formId + '"]';
        }

        const hideElements: null | Element[] = [...document.querySelectorAll(hideElementSelector)];

        if (0 === hideElements.length) {
            return;
        }

        hideElements.forEach(hideElement => {
            (hideElement as HTMLElement).style.display = 'none';
        });
    };

    const resetFormInput = (formId: string | null): void => {
        let resetElementSelector = '[data-ajax-success-reset]';

        if (null !== formId) {
            resetElementSelector = '[data-ajax-success-reset="' + formId + '"]';
        }

        const resetElements: null | Element[] = [...document.querySelectorAll(resetElementSelector)];

        resetElements.forEach(resetElement => {
            (resetElement as HTMLInputElement).value = '';
        });
    };

    const disableButton = (formElement: Element) => {
        const submitElements: Element[] = [
            ...formElement.querySelectorAll('[type="submit"]'),
            ...(formElement.id ? document.querySelectorAll(`[type="submit"][form="${formElement.id}"]`) : []),
        ];

        submitElements.forEach((element): void => {
            element.setAttribute('disabled', 'disabled');
            element.classList.add('jsDisabledOnSubmit');
        });
    };

    const enableButtons = (formElement: Element) => {
        const disabledSubmitElements: Element[] = [
            ...formElement.querySelectorAll('.jsDisabledOnSubmit'),
            ...(formElement.id ? document.querySelectorAll(`.jsDisabledOnSubmit[form="${formElement.id}"]`) : []),
        ];

        disabledSubmitElements.forEach(disabledSubmitElement => {
            disabledSubmitElement.removeAttribute('disabled');
            disabledSubmitElement.classList.remove('-submitter');
            disabledSubmitElement.classList.remove('jsDisabledOnSubmit');
        });
    };

    const redirectToSelectedPage = (formElement: Element): void => {
        const reloadUrl: null | string = formElement.getAttribute('data-refresh-url');
        if (null !== reloadUrl) {
            window.location.href = reloadUrl;
            return;
        }
    };

    const handleErrorState = (formId: string | null, response: AjaxHandlerResponseError): void => {
        if (400 <= response.status) {
            addFormMessage(response, 'error', formId);
            validator.processAjaxValidatorData(response.fieldErrors, formId);
        } else {
            addFormMessage(response, 'error', formId);
        }
    };

    const deleteHTMLElement = (formElement: Element): void => {
        const elementIdToBeDeleted: string | null = formElement.getAttribute('data-element-to-be-deleted-id');

        if (null === elementIdToBeDeleted) {
            return;
        }

        const elementToBeDeleted: HTMLElement | null = document.querySelector(
            `[data-deletabl-element-id="${elementIdToBeDeleted}"]`,
        );

        if (null !== elementToBeDeleted) {
            elementToBeDeleted.remove();
        }
    };

    const multipartFormDataCollector = (element: HTMLFormElement): FormData => {
        const tmp = new FormData(element);
        const formData = new FormData();
        tmp.forEach((value, key) => {
            if (value instanceof File) {
                formData.append(key, value, value.name);
            }
        });
        formData.append('json', simpleFormDataCollect(element));

        return formData;
    };

    const handleReloadErrorResponse = (error: AjaxHandlerResponseError): boolean => {
        if (!error.reload) {
            return false;
        }
        window.location.reload();
        return true;
    };

    const handleRedirectResponse = (success: AjaxHandlerResponse): boolean => {
        if ('redirect' !== success.type || !success.redirectUrl) {
            return false;
        }
        const currentSearchParams = new URLSearchParams(window.location.search);
        currentSearchParams.sort();
        const redirectUrl = new URL(success.redirectUrl, window.location.href);
        redirectUrl.searchParams.sort();
        if (
            redirectUrl.origin !== window.location.origin &&
            redirectUrl.pathname === window.location.pathname &&
            currentSearchParams.toString() === redirectUrl.searchParams.toString()
        ) {
            // we are asked to redirect to the current page with the current query params
            // changing location.href would not do anything, so we reload the page
            window.location.reload();
            return true;
        }
        window.location.href = success.redirectUrl;
        return true;
    };

    const openModalOnSuccess = (response: AjaxHandlerResponse): void => {
        if ('ajaxModal' === response.type) {
            modal.modalOpen(response.modalUrl, init);
        }
        if ('modal' === response.type) {
            modal.modalHtmlOpen(response.htmlContent, init);
        }
    };

    const handleAjaxPost = (
        ajaxUrl: string,
        data: HTMLFormElement,
        formElement: Element,
        dataParser: (data: HTMLFormElement) => string | FormData,
    ): void => {
        const formId: null | string = formElement.getAttribute('data-form-id');
        disableButton(formElement);
        const formData = dataParser(data);
        let headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        };
        if (formData instanceof FormData) {
            headers = {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'multipart/form-data',
            };
        }
        ajaxHandler.post(
            ajaxUrl,
            formData,
            response => {
                if (handleRedirectResponse(response)) {
                    return;
                }
                openModalOnSuccess(response);
                addFormMessage(response, 'success', formId);
                hideFormPart(formId);
                resetFormInput(formId);
                redirectToSelectedPage(formElement);
                enableButtons(formElement);
            },
            error => {
                handleErrorState(formElement.getAttribute('data-form-id'), error);
                enableButtons(formElement);
                handleReloadErrorResponse(error);
            },
            undefined,
            headers,
        );
    };

    const handleAjaxPut = (
        ajaxUrl: string,
        data: HTMLFormElement,
        formElement: Element,
        dataParser: (data: HTMLFormElement) => string | FormData | BasicObject,
    ): void => {
        const formId: null | string = formElement.getAttribute('data-form-id');

        disableButton(formElement);

        const formData = dataParser(data);
        let headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        };
        if (formData instanceof FormData) {
            headers = {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'multipart/form-data',
            };
        }
        ajaxHandler.put(
            ajaxUrl,
            formData,
            reponse => {
                if (200 !== reponse.status) {
                    return;
                }
                if (handleRedirectResponse(reponse)) {
                    return;
                }
                openModalOnSuccess(reponse);
                addFormMessage(reponse, 'success', formId);
                hideFormPart(formId);
                resetFormInput(formId);
                redirectToSelectedPage(formElement);
                enableButtons(formElement);
            },
            error => {
                handleErrorState(formElement.getAttribute('data-form-id'), error);
                enableButtons(formElement);
                handleReloadErrorResponse(error);
            },
            undefined,
            headers,
        );
    };

    const handleAjaxDelete = (ajaxUrl: string, data: HTMLFormElement, formElement: Element): void => {
        const formId: null | string = formElement.getAttribute('data-form-id');
        disableButton(formElement);
        ajaxHandler.deleteRequest(
            ajaxUrl,
            simpleFormDataCollect(data),
            response => {
                if (200 !== response.status) {
                    return;
                }
                if (handleRedirectResponse(response)) {
                    return;
                }
                addFormMessage(response, 'success', formId);
                hideFormPart(formId);
                resetFormInput(formId);
                redirectToSelectedPage(formElement);
                deleteHTMLElement(formElement);
                enableButtons(formElement);
            },
            error => {
                handleErrorState(formElement.getAttribute('data-form-id'), error);
                enableButtons(formElement);
                handleReloadErrorResponse(error);
            },
        );
    };

    const updateMultipleFileInput = (formElement: Element): void => {
        const mfiElementsInputs = formElement.querySelectorAll('.js_multipleFileInput input');

        if (0 === mfiElementsInputs.length) {
            return;
        }

        mfiElementsInputs.forEach(mfiElementsInput => {
            if ('' !== (mfiElementsInput as HTMLInputElement).value) {
                return;
            }
            const deleteButton:
                | null
                | Element
                | undefined = mfiElementsInput.parentElement?.parentElement?.querySelector('button');

            if (null === deleteButton || undefined === deleteButton) {
                return;
            }

            (deleteButton as HTMLButtonElement).click();
        });
    };

    const submitEvent = async (event: Event & { submitter?: Element }): Promise<void> => {
        event.preventDefault();
        const formElement = <Element>event.currentTarget;
        updateMultipleFileInput(formElement);

        if (null !== formElement.getAttribute('data-ajax-valid')) {
            const validateResult: boolean = validator.validateForm(formElement);

            if (!validateResult) {
                return;
            }
        }

        const ajaxUrl: null | string = formElement.getAttribute('data-api-url');
        if (null === ajaxUrl) {
            return;
        }
        const eventTarget = event.target instanceof HTMLFormElement ? event.target : undefined;
        const ajaxMethod: null | string = formElement.getAttribute('data-method');
        const errorElements: Element[] = [...document.querySelectorAll('[data-ajax-form-error]')];

        if (0 !== errorElements.length) {
            errorElements.forEach(errorElement => {
                errorElement.innerHTML = '';
            });
        }

        if (event.submitter) {
            event.submitter.classList.add('-submitter');
        }

        if (undefined === eventTarget) {
            return;
        }
        if ('post' === ajaxMethod) {
            handleAjaxPost(ajaxUrl, eventTarget, formElement, simpleFormDataCollect);
        }

        if ('put' === ajaxMethod) {
            handleAjaxPut(ajaxUrl, eventTarget, formElement, simpleFormDataCollect);
        }
        if ('postMultipart' === ajaxMethod) {
            handleAjaxPost(ajaxUrl, eventTarget, formElement, multipartFormDataCollector);
        }

        if ('putMultipart' === ajaxMethod) {
            handleAjaxPut(ajaxUrl, eventTarget, formElement, multipartFormDataCollector);
        }

        if ('delete' === ajaxMethod) {
            handleAjaxDelete(ajaxUrl, eventTarget, formElement);
        }
    };

    const init = (): void => {
        const formElements: Element[] = [...document.querySelectorAll('.jsAjaxForm')];

        if (0 === formElements.length) {
            return;
        }

        formElements.forEach(formElement => {
            formElement.removeEventListener('submit', submitEvent, false);
            formElement.addEventListener('submit', submitEvent, false);

            // dom-form-serializer automaticky vyhazuje name kliknutého buttonu z posílaných dat, sám autor doporučuje řešení ručně přidávat po kliku na button
            const submitElements: Element[] = [...formElement.querySelectorAll('button[name][value]')];

            if ('' !== formElement.id && typeof formElement.id === 'string') {
                const hiddenAdditionalParams: Element[] = [
                    ...document.querySelectorAll(`button[form=${formElement.id}][name][value]`),
                ];
                if (0 !== hiddenAdditionalParams.length) {
                    submitElements.push(...hiddenAdditionalParams);
                }
            }

            submitElements.map(submitElement => {
                submitElement.addEventListener('click', () => {
                    const submitName = submitElement.getAttribute('name');
                    const submitValue = submitElement.getAttribute('value');
                    const submitValueType = submitElement.getAttribute('data-value-type');
                    if (null === submitName || null === submitValue) {
                        return;
                    }
                    let inputs: Element[] = [...document.querySelectorAll('input[name=' + submitName + ']')];

                    if (0 === inputs.length) {
                        const submitInput = document.createElement('input');
                        submitInput.setAttribute('type', 'hidden');
                        submitInput.setAttribute('name', submitName);
                        formElement.appendChild(submitInput);
                        inputs = [submitInput];
                    }
                    inputs.forEach((input: Element) => {
                        input.setAttribute('value', submitValue);
                        if (submitValueType !== null) {
                            input.setAttribute('data-value-type', submitValueType);
                        } else {
                            input.removeAttribute('data-value-type');
                        }
                    });
                });
            });
        });
    };

    return {
        init,
    };
};

export default AjaxForm;
